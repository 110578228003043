import React, { lazy, useEffect, useState, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./Components/Loading/Loading.jsx";
const App = lazy(() => import("./App.jsx"));
const Home = lazy(() => import("./Pages/Home/Home.jsx"));
const About = lazy(() => import("./Pages/About/About.jsx"));
const Courses = lazy(() => import("./Pages/Courses/Courses.jsx"));
const Projects = lazy(() => import("./Pages/Projects/Projects.jsx"));
const Error = lazy(() => import("./Pages/Error404/Error404.jsx"));
const PlaceUP = lazy(() =>
  import("./Pages/Projects/ProjectsPages/PlaceUP.jsx")
);
const Pokedex = lazy(() =>
  import("./Pages/Projects/ProjectsPages/Pokedex.jsx")
);
const AnimalSpace = lazy(() =>
  import("./Pages/Projects/ProjectsPages/AnimalSpace.jsx")
);
const Governo = lazy(() =>
  import("./Pages/Projects/ProjectsPages/Governo.jsx")
);
const Pizzaria = lazy(() =>
  import("./Pages/Projects/ProjectsPages/Pizzaria.jsx")
);
const Instagram = lazy(() =>
  import("./Pages/Projects/ProjectsPages/Instagram.jsx")
);
const ToDoList = lazy(() =>
  import("./Pages/Projects/ProjectsPages/ToDoList.jsx")
);
const BabaKing = lazy(() =>
  import("./Pages/Projects/ProjectsPages/BabaKing.jsx")
);
const Raira = lazy(() => import("./Pages/Projects/ProjectsPages/Raira.jsx"));
const WeatherApp = lazy(() =>
  import("./Pages/Projects/ProjectsPages/WeatherApp.jsx")
);

export function Router() {
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="*" element={<Error />} />

          <Route path="/" element={<App />} />

          <Route path="/Home" element={<Home />} />
          <Route path="/Inicio" element={<Home />} />

          <Route path="/About" element={<About />} />
          <Route path="/Sobre" element={<About />} />

          <Route path="/Course" element={<Courses />} />
          <Route path="/Courses" element={<Courses />} />
          <Route path="/Formacao" element={<Courses />} />
          <Route path="/Formação" element={<Courses />} />
          <Route path="/Cursos" element={<Courses />} />
          <Route path="/Curso" element={<Courses />} />

          <Route path="/Projeto" element={<Projects />} />
          <Route path="/Projetos" element={<Projects />} />
          <Route path="/Project" element={<Projects />} />
          <Route path="/Projects" element={<Projects />} />

          <Route path="/Projetos/PlaceUP" element={<PlaceUP />} />
          <Route path="/Projetos/Pokedex" element={<Pokedex />} />
          <Route path="/Projetos/AnimalSpace" element={<AnimalSpace />} />
          <Route path="/Projetos/Governo" element={<Governo />} />
          <Route path="/Projetos/Pizzaria" element={<Pizzaria />} />
          <Route path="/Projetos/Instagram" element={<Instagram />} />
          <Route path="/Projetos/ToDoList" element={<ToDoList />} />
          <Route path="/Projetos/BabaKing" element={<BabaKing />} />
          <Route path="/Projetos/Raira" element={<Raira />} />
          <Route path="/Projetos/WeatherApp" element={<WeatherApp />} />
        </Routes>
      )}
    </Suspense>
  );
}
